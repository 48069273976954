import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import * as colors from "../../styles/color"


const NavItem = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  font-wieght: normal;
  text-weight: normal;



  }

  :hover {
    color: ${colors.manatee};
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 2rem;
    z-index: 6;
  }
`

const NavbarLinks = () => {
  return (
    <>
      <NavItem to="/about">About</NavItem>
      <NavItem to="/albums">Albums</NavItem>
      <NavItem to="/works">Works</NavItem>
      <NavItem to="/contact">Contact</NavItem>
    </>
  )
}

export default NavbarLinks