import * as color from "./color"

export const pageStyles = {
    paddingTop: "25px"
  }

export const italicDesc = {
  fontStyle: "italic"
}
  
  export const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 500,
    fontSize: "2.5rem",
    color: color.richblack,
  }

  export const categoryStyle = {
    color: color.richblack,
    fontSize: "1.5rem",
    marginBottom: 20,

  }

  export const altLinkStyle = {
    color: color.manatee,
    fontsize: "13px"
  }

  export const headingAccentStyles = {
    color: color.manatee,
    fontSize: "2rem",
  }
  
  export const paragraphStyles = {
    marginBottom: 48,
  }

  export const codeStyles = {
    color: "#8A6534",
    padding: 0,
    backgroundColor: "#FFF4DB",
    fontSize: "1.25rem",
    borderRadius: 4,
  }
  export const listStyles = {
    marginBottom: 96,
  }
  
  export const listItemStyles = {
    fontWeight: "300",
    fontSize: "24px",
    maxWidth: "560px",
  }
  
  export const linkStyle = {
    color: color.manatee,
    fontWeight: "bold",
    fontSize: "20px"
  }
  
  export const docLinkStyle = {
    ...linkStyle,
    listStyleType: "none",
    marginBottom: 24,
  }
  
  export const descriptionStyle = {
    color: color.richblack,
    fontSize: "20px",
  }
  
  export const imageStyle = {
    borderRadius: "7px",
    maxWidth: "225px",
    maxHeight: "225px",
    marginBottom: 70,
  }
  
  export const docLink = {
    text: "Documentation",
    url: "https://www.gatsbyjs.com/docs/",
    color: "#8954A8",
  }

  export const contactStyle = {
    color: color.raisinblack,
    fontSize: "20px",
    fontWeight: "bold",
  }

  export const bottomSpacer = {
      marginBottom: 64
  }

  export const buttonStyle = {
      backgroundColor: color.raisinblack,
      borderColor: color.manatee,
  }