import React from "react"
import Helmet from "react-helmet"
import Navbar from "./navbar/navbar.js"
import {Spotify, Apple, Facebook, SoundCloud} from "./footerSVG"
import SEO from "./SEO/seo.js"
import 'bootstrap/dist/css/bootstrap.min.css';

const socialGrid = {
  display: "grid",
  gridTemplateColumns: "36px 36px 36px 36px"
}

const pageStyle = {
    paddingLeft: "120px",
}

const socialStyle = {
  paddingLeft: "120px",
  backgroundColor: "#ffffff",
}

const footerStyle = {
  fontWeight: 100
}


export default ({ children }) => (
  <>
    <SEO>
       <link rel="stylesheet" href="https://use.typekit.net/syk0xbq.css"></link>
    </SEO>
    <div>
      <header>
        <h2>
          <Navbar>

          </Navbar>
        </h2>
      </header>
      {children}
      <footer> 
        <p>
        <span style={footerStyle}> {`© ${new Date().getFullYear()} Liam Kaplan`} </span>
        </p>
        <p>
            <span style = {socialGrid}> <Facebook/>  <Apple/> <Spotify/>  <SoundCloud/>   </span>
        </p>
      </footer>
    </div>
  </>
)