import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ post }) => {

  /*
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          baseUrl
        }
      }
    }
  `);
  */

  const data = {
    title: "Liam Kaplan Music",
    description: "Liam Kaplan — Pianist & Composer Official Website.",
    baseUrl: "https://www.liamkaplanmusic.com/"
  }

  //const defaults = data.site.siteMetadata;
  const defaults = data

  if (defaults.baseUrl === '' && typeof window !== 'undefined') {
    defaults.baseUrl = window.location.origin;
  }


  const title = defaults.title;
  const description = defaults.description;
  const url = new URL(defaults.baseUrl);

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
};

export default SEO;