import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

const LogoWrap = styled.div`
  margin: auto 0;
  flex: 0 1 48px;
  
  :hover {
    opacity: 70%;
  }

  @media (max-width: 768px) and (orientation: landscape) {
    flex: 0 1 25px;
  }
  `

const Logo = () => {

  return (
      <LogoWrap as = {Link} to="/">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.5 36"><path d="M4.93,40.07v-36h7.7V33.62H26.48v6.45Z" transform="translate(-4.93 -4.07)" fill="#979faf"/><path d="M34.13,21.07l14-17h9.1L44.48,19.12l13,21h-8.6l-9.55-16-5.15,5.85V40.07h-7.7v-36h7.7Z" transform="translate(-4.93 -4.07)" fill="#fff3eb"/></svg>
      </LogoWrap>
  )
}

export default Logo