export const seashell = "#FFF3EB"

export const manatee = "#979FAF"

export const raisinblack = "#1C2435"

export const richblack = "#0A0A0A"

export const white = "#ffffff"


